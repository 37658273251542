// @flow
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import Image from '../../components/image';
import GlossaryLink from '../../components/glossary-link';
import type { FrontMatter, ImageType } from '../../utils/types';
import { withFrontMatter } from '../../components';
import CanyonRatingCard from '../../components/canyon-rating-card';
import AllImagesLink from '../../components/all-images-link';
import Link from '../../components/link';

type Props = {
  frontMatter: FrontMatter,
  data: {
    _DSF8693: ImageType,
    _DSF8696: ImageType,
    _DSF8697: ImageType,
    _DSF8700: ImageType,
    _DSF8706: ImageType,
    _DSF8709: ImageType,
    _DSF8712: ImageType,
    _DSF8714: ImageType,
    _DSF8716: ImageType,
  },
};

class Blog_2018_11_10_Tierdrop_Not_Tierdrop extends React.Component<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter} toc>
        <p>
          Day 2 of our canyoneering trip we met up with a group of people to do
          the Tierdrops. We met up in the morning, introduced ourselves, and
          headed off to Park Avenue. These were great canyons for our group
          since none of us were familiar with each other's abilities, the days
          were short, and the canyons were beginner friendly.
        </p>
        <h2>Tierdrop</h2>
        <CanyonRatingCard rating="3AII" title="Tierdrop" />
        <Image image={data._DSF8693} caption="Starting the day" />
        <p>
          I love going with people I have never met before. It's like, "Hello,
          I'm Jared, trust me with your life and I'm going to trust my life to
          you." Of course I do what I feel is necessary to ensure that everyone
          is safe despite not knowing each other and I expect the same from
          everyone else.
        </p>
        <p>
          Our group today were my friends Trent and Brad, Bekah and Austin,
          Lindsey, and Katy and Bruce. I believe it was each group's first time
          to meet each other.
        </p>
        <p>
          After the steep ascent we headed over to{' '}
          <Link to="http://ropewiki.com/Tierdrop_Canyon">Tierdrop,</Link>{' '}
          identified the current <GlossaryLink>anchor</GlossaryLink> and{' '}
          <GlossaryLink>rigging</GlossaryLink>, and Lindsey set up the rappel.
          Bekah was volunteered to be the last person down and set everything up
          perfectly and got the rope down.
        </p>
        <Image
          image={data._DSF8696}
          caption="Bekah on rappel with Austin providing a belay"
        />
        <p>
          Rap 2 started with a <GlossaryLink>biner block</GlossaryLink> and we
          switched it to an <GlossaryLink>Munter Mule Overhand</GlossaryLink>{' '}
          after the first person went down. Bekah again was the last person
          down.
        </p>
        <Image image={data._DSF8697} caption="Trent on the second rappel" />
        <Image
          image={data._DSF8700}
          caption="Bruce, Brad, and Lindsey on deck"
        />
        <p>
          For the final rappel, Austin rigged a{' '}
          <GlossaryLink>Jester</GlossaryLink> in a slightly different way that I
          hadn't seen before. The Jester part is the same, but instead of
          nothing or a simple slip knot to prevent the rope from creeping, he
          tied a Mule Overhand. I suspect the Mule is probably easier to release
          than the slip knot when weighted.
        </p>
        <Image
          image={data._DSF8706}
          caption="Austin at the top of the final rappel"
        />
        <p>
          Again, Bekah was the last person down. We ran out as far as we could
          when pulling the ropes to minimize grooves. It came down without
          difficulty.
        </p>
        <Image image={data._DSF8709} caption="Last rappel" />
        <Image image={data._DSF8712} caption="Group shot at the last rappel" />
        <h2>Not Tierdrop</h2>
        <CanyonRatingCard rating="3AII" title="Not Tierdrop" />
        <p>
          After finishing Tierdrop we had lunch and then ran down to the
          Visitor's Center and got permits for the Fiery Furnace for the next
          day. Once that was taken care of we headed back up to do{' '}
          <Link to="http://ropewiki.com/Not_Tierdrop_Canyon">Not Tierdrop</Link>
          .
        </p>
        <p>
          This was a new canyon to me and the only beta-ed canyon in Arches that
          I had not yet done. Unfortunately I didn't take many pictures because
          I was busy rigging and completely forgot. I'll include what I have,
          though. We weren't planning on coming back up to the top of Park
          Avenue this canyon, we chose to rig the first rappel{' '}
          <GlossaryLink id="retrievable-rigging-system">
            retrievable
          </GlossaryLink>
          .
        </p>
        <Image
          image={data._DSF8714}
          caption="View looking at the rest of the canyon from the bottom of the first rappel"
        />
        <p>
          The canyon ended quickly and we decided to prolong the fun by
          rappelling the optional rappel at the end. Normally one would walk
          down a sand berm{' '}
          <GlossaryLink id="left-down-canyon">LDC</GlossaryLink> but we pulled
          out the <GlossaryLink>toggle</GlossaryLink> again, rigged around a
          giant tree, and descended down the next tier.
        </p>
        <Image
          image={data._DSF8716}
          caption="Walk-down sand berm. We rappelled down the cliff on the left side of this image"
        />
        <p>
          After this we made our way around the corner to the bottom of Tierdrop
          and back to our cars. A couple of people had to leave that night so we
          said our goodbyes. I was very happy and impressed with each person in
          the group.
        </p>
        <AllImagesLink id={frontMatter.id} />
      </Layout>
    );
  }
}

export default withFrontMatter('2018-11-10-tierdrop-not-tierdrop')(
  Blog_2018_11_10_Tierdrop_Not_Tierdrop
);

export const query = graphql`
  query {
    _DSF8693: file(
      relativePath: {
        eq: "images/blog/2018-11-10-tierdrop-not-tierdrop/_DSF8693.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8696: file(
      relativePath: {
        eq: "images/blog/2018-11-10-tierdrop-not-tierdrop/_DSF8696.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8697: file(
      relativePath: {
        eq: "images/blog/2018-11-10-tierdrop-not-tierdrop/_DSF8697.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8700: file(
      relativePath: {
        eq: "images/blog/2018-11-10-tierdrop-not-tierdrop/_DSF8700.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8706: file(
      relativePath: {
        eq: "images/blog/2018-11-10-tierdrop-not-tierdrop/_DSF8706.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8709: file(
      relativePath: {
        eq: "images/blog/2018-11-10-tierdrop-not-tierdrop/_DSF8709.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8712: file(
      relativePath: {
        eq: "images/blog/2018-11-10-tierdrop-not-tierdrop/_DSF8712.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8714: file(
      relativePath: {
        eq: "images/blog/2018-11-10-tierdrop-not-tierdrop/_DSF8714.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8716: file(
      relativePath: {
        eq: "images/blog/2018-11-10-tierdrop-not-tierdrop/_DSF8716.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
